import React, { Component } from "react";
import "./PlanoTable.css";
import $ from "jquery";

export default class PLanoTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pos: 3,
      mediaQuery: null,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    this.setState({ mediaQuery: window.matchMedia("(min-width: 768px)") });
  }

  componentDidMount() {
    /*
     ** Somente para RESPONSIVO:
     ** Mostrar o conteúdo do que esta ativo
     */
    $("tr").find("td:not(:eq(0))").hide();
    $("td:nth-child(" + this.state.pos + ")").css("display", "table-cell");
    $("tr").find("th:not(:eq(0))").hide();

    this.doSomething(this.state.mediaQuery);
    this.state.mediaQuery.addListener(this.doSomething);
  }

  doSomething(mediaQuery) {
    if (mediaQuery.matches) {
      $(".sep").attr("colSpan", 3);
    } else {
      $(".sep").attr("colSpan", 2);
    }
  }

  handleClick(event) {
    var pos = +event.target.value + 1;
    $("tr").find("td:not(:eq(0))").hide();
    $("td:nth-child(" + pos + ")").css("display", "table-cell");
    $("tr").find("th:not(:eq(0))").hide();
    $("li").removeClass("active");
    $(event.currentTarget).addClass("active");
  }

  render() {
    return (
      <article className="plano-table mb-5 mt-5">
        <ul className="pl-0">
          <li className="bg-grey" onClick={this.handleClick}>
            <button value={1}>Starter</button>
          </li>
          <li className="bg-black active" onClick={this.handleClick}>
            <button value={2}>Elite</button>
          </li>
        </ul>

        <table>
          <thead>
            <tr>
              <th className="hide"></th>
              <th className="bg-grey">Starter</th>
              <th className="bg-black default">Elite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Preço Mensal</td>
              <td>
                <span className="txt-top">R$</span>
                <span className="txt-l"> 49,90 </span>
                <span className="txt-top">por carro</span>
                <br />
                <span className="txt-s">Valor a partir de 5 veículos</span>
              </td>
              <td>
                <span className="txt-top">R$</span>
                <span className="txt-l"> 109,90 </span>
                <span className="txt-top">por carro</span>
                <br />
                <span className="txt-s">Valor a partir de 5 veículos</span>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="sep">
                ISENTO TAXA DE ADESÃO
              </td>
            </tr>
            <tr>
              <td>Frota ativa e desmobilizada (com histórico)</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Manutenções preventivas e corretivas</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Sinistros e pneus</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Abastecimento, pedágio e estacionamento</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Controle de multas e licenciamento</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Seguros, financiamentos e outras despesas</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Alertas de chamada preventiva</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Controle e lembretes de vencimento de CNH</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Pool de movimentações de veículos</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Relatórios e dashBoard</td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Localização em tempo real</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Histórico de localizações</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Sensores de velocidade</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Relatório de consumo</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Relatório de alarme</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
            <tr>
              <td>Cerca eletrônica de rastreamento</td>
              <td>
                <span className="no-tick">✘</span>
              </td>
              <td>
                <span className="tick">&#10004;</span>
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    );
  }
}
