import React from 'react'
import './Footer.css'

export default (props) => (
  <footer className="padding-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-6">
          <div className="row">
            <div className="col-12 d-flex flex-row justify-content-center">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="ml-4">
                <h4>Endereço</h4>
                <p className="text-nowrap info-text">Votorantim / SP - Brasil</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="footer-icon">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="ml-4">
                <h4>E-Mail</h4>
                <p className="text-nowrap info-text">carroramaa@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12 text-center midias-sociais">
          <a
            href="https://www.facebook.com/carroramaApp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-facebook-square p-2"></i>
          </a>
          <a
            href="https://www.instagram.com/carrorama/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-instagram p-2"></i>
          </a>
          <a
            href="https://pt.linkedin.com/company/carrorama"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-linkedin p-2"></i>
          </a>
        </div>
        <div className="col-md-12">
          <p className="text-center info-text">
            2021 - Todos os direitos reservados. Carrorama® é desenvolvido por{" "}
            <a
              href="https://going2.com.br/"
              rel="noopener noreferrer"
              target="_blank"
            >
              GOING2 MOBILE.
            </a>
          </p>
        </div>
        <div className="col-md-12">
          <p className="text-center info-text">
            <a
              href="http://lgpd.going2.com.br/carrorama?type=contactUs"
              rel="noopener noreferrer"
              target="_blank"
            >
              Canal de atendimento para o titular de dados pessoais
            </a>
          </p>
        </div>
        <div className="col-md-12">
          <p className="text-center info-text">
            <a
              href="http://lgpd.going2.com.br/carrorama"
              rel="noopener noreferrer"
              target="_blank"
            >
              Programa de Proteção de Dados Pessoais
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);