import React from "react";

//Dependências Internas
import { CNPJ, Telefone } from "../../mask/Mask";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome_completo: "",
      email: "",
      cnpj: "",
      empresa: "",
      quantidade_carros: 0,
      telefone: "",
      mensagem: "",
      mensagemEnviada: false,
      isSendingMail: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
  }

  componentWillMount() {
    let pathname = window.location.pathname.split("/");
    pathname.map((path) => {
      if (path === "obrigado") {
        this.setState({ mensagemEnviada: true });
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("event", "conversion", {
          send_to: "AW-994503815/nB0rCLm7zrUBEIfZm9oD",
        });
      }
    });
  }

  render() {
    return (
      <form id="contato-form" onSubmit={this.handleSubmit}>
        <div className="row">
          {this.state.mensagemEnviada && (
            <div className="col-md-12">
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <strong>Sucesso!</strong> Sua mensagem foi enviada para nossos
                atendentes!
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          )}
          <div className="col-md-6 mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              name="nome_completo"
              id="nome-completo"
              aria-describedby="Nome Completo"
              placeholder="Nome Completo *"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="email"
              className="form-control form-control-lg"
              name="email"
              id="email"
              aria-describedby="Email"
              placeholder="Email *"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              name="empresa"
              id="empresa"
              aria-describedby="Nome Completo"
              placeholder="Empresa *"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              name="cnpj"
              id="cnpj"
              aria-describedby="CNPJ"
              placeholder="CNPJ"
              onChange={(e) => {
                e.target.value = CNPJ(e.target.value);
                this.handleChange(e);
              }}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="number"
              className="form-control form-control-lg"
              name="quantidade_carros"
              id="quantidade-carros"
              aria-describedby="Quantidade carros"
              placeholder="Quantidade de Carros *"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              name="telefone"
              id="telefone"
              aria-describedby="Telefone"
              placeholder="Telefone *"
              required
              onChange={(e) => {
                e.target.value = Telefone(e.target.value);
                this.handleChange(e);
              }}
            />
          </div>
          <div className="col-md-12">
            <textarea
              className="form-control form-control-lg"
              name="mensagem"
              id="mensagem"
              aria-describedby="Mensagem"
              placeholder="Mensagem"
              required
              rows="3"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-12 mt-4">
            {this.state.isSendingMail ? (
              <button className="btn btn-primary" disabled>
                <i className="fa fa-circle-notch fa-spin load-icon-submit"></i>{" "}
                Solicitar Teste Grátis
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Solicitar Teste Grátis
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSendingMail: true });
    this.sendFeedback({
      nome_completo: this.state.nome_completo,
      email: this.state.email,
      empresa: this.state.empresa,
      cnpj: this.state.cnpj,
      quantidade_carros: this.state.quantidade_carros,
      telefone: this.state.telefone,
      mensagem: this.state.mensagem,
    });
  }

  sendFeedback(variables) {
    const url = process.env.REACT_APP_FLEET_STARTER_API_URL + "/outrosrecursos/enviaremailcontato";

    const headers = {
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({
      nome: variables.nome_completo,
      email: variables.email,
      empresa: variables.empresa,
      cnpj: variables.cnpj,
      qtdCarros: variables.quantidade_carros,
      telefone: variables.telefone,
      mensagem: variables.mensagem,
    });

    fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    })
      .then((response) => response.json())
      .then(() => {
        document.getElementById("contato-form").reset();
        this.setState({ isSendingMail: false, mensagemEnviada: true });
        window.location.href = "/obrigado#contato";
      })
      .catch((error) => {
        this.setState({ isSendingMail: false });
        console.error("Error:", error);
      });

    window.location.href = "obrigado#contato";
  }
}
